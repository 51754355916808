import React, { Component } from "react";
import "./css/Receipts.css"; // Create a separate CSS file for styling
import LogoHeader from "./LogoHeader";
import Spinner from "./Spinner";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

class Batches extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [],

      attendeeError: "",
      newSearch: {
        Identifier: "",
      },
      searchReceipt: {
        Receipt_ID: "",
        Receipt_Number: "",
        Amount: "",
        Name: "",
      },
      receiptInfo: null,
      receiptError: null,
      attendee: null,
      error: null,
      isLoading: false,
      screenWidth: 0,
      batches: [
        { name: "Batch 908 Control Report", date:"2024-04-04", link: "https://batch-reports-pdf.s3.amazonaws.com/Batch%20908%20Control%20Report.pdf" },
        { name: "Batch 908 Validation Report", date:"2024-04-04", link: "https://batch-reports-pdf.s3.amazonaws.com/Batch%20908%20Validation%20Report.pdf" },
        { name: "Batch 910 Control Report", date:"2024-04-16", link: "https://batch-reports-pdf.s3.amazonaws.com/Batch%20910%20Control%20Report.pdf" },
        { name: "Batch 910 Validation Report", date:"2024-04-16", link: "https://batch-reports-pdf.s3.amazonaws.com/Batch%20910%20Validation%20Report.pdf" },
        { name: "Batch 911 Control Report", date:"2024-04-16", link: "https://batch-reports-pdf.s3.amazonaws.com/Batch%20911%20Control%20Report.pdf" },
        { name: "Batch 911 Validation Report", date:"2024-04-16", link: "https://batch-reports-pdf.s3.amazonaws.com/Batch%20911%20Validation%20Report.pdf" },
        { name: "Batch 912 Control Report", date:"2024-04-17", link: "https://batch-reports-pdf.s3.amazonaws.com/Batch%20912%20Control%20Report.pdf" },
        { name: "Batch 912 Validation Report", date:"2024-04-17", link: "https://batch-reports-pdf.s3.amazonaws.com/Batch%20912%20Validation%20Report.pdf" }, // Please provide the link for this file
      ],
      filters: [
        { name: "All", value: "all" },
        { name: "Not Mailed yet", value: "unmailed" },
        { name: "Already Mailed", value: "mailed" }
      ],


      receiptType: "",
      receiptFilter: "all",
      intervalId: null, // Store the interval ID in state
      receiptList: [],
      searchList: [],
      notesText: "",
      expandedRowID: null,
      expandedRowType: null,
    };
  }

  async componentDidMount() {
    this.handleResize = () => {
      this.setState({ screenWidth: window.innerWidth });
    };

    // Add an event listener to update the screenWidth when the window is resized
    window.addEventListener("resize", this.handleResize);
  }

  componentWillUnmount() {
    // Clean up the event listener when the component unmounts
    window.removeEventListener("resize", this.handleResize);
    clearInterval(this.state.intervalId);
  }

  render() {
    const isIPad = this.state.screenWidth <= 1200;

    return (
      <>
        <LogoHeader />
        <main>
       

          <section className="container stylization maincont">
          <h1 className="main-ttl">
            <span>Batch Reports</span>
          </h1>
          <div className="cart-items-wrap">
            <table className="cart-items">
              <thead>
                <tr>
                  <td className="cart-ttl">Name</td>
                  <td className="cart-ttl">Date Batched</td>
                  <td className="cart-ttl">Download</td>
                </tr>
              </thead>

              <tbody>
                {this.state.batches.map((batch, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td>
                        {batch.name}
                      </td>
                      <td>
                      {batch.date}
                      </td>
                      <td>
                      <a href={batch.link}>LINK</a>
                      </td>
                      

                    </tr>
                    
                  </React.Fragment>
                ))}
              </tbody>
            </table>
          </div>
        </section>
          <ToastContainer />
          {this.state.isLoading && <Spinner />}
        </main>
      </>
    );
  }
}

export default Batches;
