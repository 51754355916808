import React from "react";
import logo from "./img/logo.png"
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faHandHoldingUsd } from '@fortawesome/free-solid-svg-icons';
import { faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { faPrint } from "@fortawesome/free-solid-svg-icons";
import { faTicket } from "@fortawesome/free-solid-svg-icons";
import { faDisplay } from "@fortawesome/free-solid-svg-icons";
import { faWineBottle } from "@fortawesome/free-solid-svg-icons";
import { faReceipt } from "@fortawesome/free-solid-svg-icons";
import { faList } from "@fortawesome/free-solid-svg-icons";
import { faComputer } from "@fortawesome/free-solid-svg-icons";
import { faDonate } from "@fortawesome/free-solid-svg-icons";

function LogoHeader() {

  return (
    <header className="header">
    <div className="header-middle">
      <div className="container header-middle-cont">
        <div className="toplogo">
          <a href="/">
            <img src={logo} alt="Big Sisters of BC Lower Mainland" />
          </a>
        </div>
        <div className="shop-menu social-icons">
          <ul>
            <li>
              <a href="/receipts">
              <FontAwesomeIcon icon={faReceipt} />&nbsp;
                <span className="shop-menu-ttl">Receipts</span>
              </a>
            </li>
            <li>
              <a href="/transactions">
              <FontAwesomeIcon icon={faList} />&nbsp;
                <span className="shop-menu-ttl">Transactions</span>
              </a>
            </li>
            <li>
              <a href="/batches">
              <FontAwesomeIcon icon={faDonate} />&nbsp;
                <span className="shop-menu-ttl">Batch Reports</span>
              </a>
            </li>
            <li>
              <a href="/admin">
              <FontAwesomeIcon icon={faComputer} />&nbsp;
                <span className="shop-menu-ttl">Admin</span>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    </header>
  );
}

export default LogoHeader;