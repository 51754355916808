import React, { Component } from "react";
import "./css/Transactions.css"; // Create a separate CSS file for styling
import LogoHeader from "./LogoHeader";
import Spinner from "./Spinner";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import TransactionList from "./TransactionList";
import Form from "react-bootstrap/Form";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import * as XLSX from 'xlsx';

class Transactions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      data: [], // Modify state properties as needed for transactions

      // Add state properties specific to Transactions class
      fromDate: "",
      toDate: "",
      originURL: "",
      selectedPayoutID: "",
      payoutIDs: [], // Add payout ID options
      isLoading: false,
      error: null,
      originURLs: [],
      filteredData: [],
      setData: [],
      payoutIDList: ["2024-03-07"],
      payoutData: []
      // Add other necessary state properties
    };
  }

  // Lifecycle method called after the component has been mounted
  componentDidMount() {
    // Call fetchTransactions function when the component is mounted
    this.fetchTransactions();
  }

  convertToDollars(amount) {
    return (amount / 100).toLocaleString('en-US', { style: 'currency', currency: 'USD' });
  }

  mapDonation = (donation) => {
    let manager = "";
    if (donation.originURL.includes("cobs_panorama")) {
      manager = "Marley";
    } else if (donation.originURL.includes("mjibril")) {
      manager = "Nathania";
    }

    return {
      "Checkout ID": donation.ID,
      "Date & Time": donation.createDate,
      "Relationship Manager": manager,
      "First Name": donation.firstName,
      "Middle Name": "",
      "Last Name": donation.lastName,
      "Gross Paid Amount": this.convertToDollars(donation.donationAmount),
      "Total Fee": this.convertToDollars(donation.stripeFees + donation.ourFees),
      "Net Total": this.convertToDollars(donation.depositedToBigSisters),
      "Refund Amount": 0,
      "Refund Type": "", // No refund type provided in original data
      "Total Tax Receiptable Amount": this.convertToDollars(donation.donationAmount), // No tax receiptable amount provided in original data
      "Tax Receipt Number": donation.ReceiptNumber, // No tax receipt number provided in original data
      "Fee Paid by Donor": donation.payFees ? this.convertToDollars(donation.stripeFees + donation.ourFees) : 0, // No fee paid by donor provided in original data
      "Payment Card": donation.paymentCard, // Assuming payment method is always Stripe
      "Payment Method": donation.paymentMethod, // Assuming payment method is always Stripe
      "Donation URL": donation.originURL, 
      "Email": donation.email,
      "Phone Number": donation.phoneNumber,
      "Company Name": donation.organizationName, 
      "Address Line 1": donation.addressLine1,
      "Address Line 2": donation.addressLine2,
      "City": donation.city,
      "Province": donation.province,
      "Postal Code": donation.zip,
      "Country": donation.country,
      "Campaign": "Unassigned",
      "Appeal": "Third Party 2024",
      "Package": "OLX", // No package provided in original data
      "Fund": "Unrestricted"
    };
  };


  // Fetch transaction data from the API
  fetchTransactions = async () => {
    try {
      this.setState({ isLoading: true });
      // Make API call to fetch transaction data
      const response = await axios.get(`${process.env.REACT_APP_API_URL}/transactions`);
      console.log("SCREAM");
      console.log(response);
      const originURLs = [...new Set(response.data.map(transaction => transaction.originURL)
        .filter(url => !url.includes("localhost"))
    )];
    
      const records = response.data;

      // Update state with fetched data
      this.setState({ data: response.data, originURLs: originURLs, isLoading: false });
    } catch (error) {
      // Handle errors
      this.setState({ error: error.message, isLoading: false });
    }
  };

  // Handle changes in the date range
  handleFromDateChange = (value) => {
    this.setState({ fromDate: value });
  };

  handleToDateChange = (value) => {
    this.setState({ toDate: value });
  };

  // Handle changes in the origin URL dropdown
  handleOriginURLChange = (event) => {
    this.setState({ originURL: event.target.value });
  };


  downloadTransactions = (e) => {
    e.preventDefault();
    let donations = this.state.filteredData;
    if (this.state.filteredData.length === 0) {
    // Perform validation for required fields (date range and origin URL)
    if (!this.state.fromDate || !this.state.toDate || !this.state.originURL) {
      this.setState({ error: "Please fill in all required fields" });
      return;
    }

        // Adjust the end date to include the full range of the selected day
        const endDate = new Date(this.state.toDate);
        endDate.setHours(23);
        endDate.setMinutes(59);
        endDate.setSeconds(59);
    
      
        // Filter transactions based on criteria
        donations = this.state.data.filter(transaction => {
          // Check if transaction's createDate falls within the adjusted date range
          const transactionDate = new Date(transaction.createDate);
    
          const fromDate = new Date(this.state.fromDate);
          fromDate.setHours(0);
          fromDate.setMinutes(0);
          fromDate.setSeconds(1);
    
          const withinDateRange = transactionDate >= fromDate && transactionDate <= endDate;
      
          // Check if transaction's originURL matches the selected originURL
          const matchesOriginURL = transaction.originURL === this.state.originURL;
      
          // Return true if both criteria are met
          return withinDateRange && matchesOriginURL;
        });
    }

    // Map the donations data to the new format
    const mappedDonations = donations.map(this.mapDonation);

    // Convert mapped data to worksheet
    const wsMapped = XLSX.utils.json_to_sheet(mappedDonations);

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Add the mapped data as a new worksheet
    XLSX.utils.book_append_sheet(wb, wsMapped, "Summary");
    

    // Add the original data
    const wsOriginal = XLSX.utils.json_to_sheet(donations);
    XLSX.utils.book_append_sheet(wb, wsOriginal, "Original Transactions");


    // Generate a downloadable file
    const filename = "transactions.xlsx";
    XLSX.writeFile(wb, filename);


    toast.success("Transactions downloaded!");
  };




// Search for transactions based on criteria
searchForTransactions = (event) => {
    event.preventDefault();
    // Perform validation for required fields (date range and origin URL)
    if (!this.state.fromDate || !this.state.toDate || !this.state.originURL) {
      this.setState({ error: "Please fill in all required fields" });
      return;
    }
  
    // Adjust the end date to include the full range of the selected day
    const endDate = new Date(this.state.toDate);
    endDate.setHours(23);
    endDate.setMinutes(59);
    endDate.setSeconds(59);

  
    // Filter transactions based on criteria
    const filteredTransactions = this.state.data.filter(transaction => {
      // Check if transaction's createDate falls within the adjusted date range
      const transactionDate = new Date(transaction.createDate);

      const fromDate = new Date(this.state.fromDate);
      fromDate.setHours(0);
      fromDate.setMinutes(0);
      fromDate.setSeconds(1);

      const withinDateRange = transactionDate >= fromDate && transactionDate <= endDate;
  
      // Check if transaction's originURL matches the selected originURL
      const matchesOriginURL = transaction.originURL === this.state.originURL;
  
      // Return true if both criteria are met
      return withinDateRange && matchesOriginURL;
    });
  
    // Update state with filtered transactions
    this.setState({ filteredData: filteredTransactions }, ()=> {
      toast.success("Transactions searched!");});
  };
  
  filterTransactionsByIds = (ids) => {
    const filteredTransactions = this.state.data.filter(transaction => ids.includes(transaction.ID));

    return filteredTransactions;
  };

  listPayoutTransactions = (e) => {
    e.preventDefault();
    const ids = ['abe5d530-1637-49c1-abfd-2513a9e2b9f6', 'f869a8ec-67a1-48d6-8e57-e6db03aba9f5'];

    const filteredTransactions = this.filterTransactionsByIds(ids);

    this.setState({payoutData: filteredTransactions});
  }
  


  downloadPayoutTransactions = (e) => {
    e.preventDefault();
    const ids = ['abe5d530-1637-49c1-abfd-2513a9e2b9f6', 'f869a8ec-67a1-48d6-8e57-e6db03aba9f5'];

    const filteredTransactions = this.filterTransactionsByIds(ids);

    // Map the donations data to the new format
    const mappedDonations = filteredTransactions.map(this.mapDonation);

    // Convert mapped data to worksheet
    const wsMapped = XLSX.utils.json_to_sheet(mappedDonations);

    // Create a new workbook
    const wb = XLSX.utils.book_new();

    // Add the mapped data as a new worksheet
    XLSX.utils.book_append_sheet(wb, wsMapped, "Summary");
    

    // Add the original data
    const wsOriginal = XLSX.utils.json_to_sheet(filteredTransactions);
    XLSX.utils.book_append_sheet(wb, wsOriginal, "Original Transactions");


    // Generate a downloadable file
    const filename = "transactions.xlsx";
    XLSX.writeFile(wb, filename);


    toast.success("Transactions downloaded!");
  };




  // Add lifecycle methods and other methods specific to Transactions class

  render() {
    // Modify the JSX rendering to fit the Transactions component
    return (
      <>
        <LogoHeader />
        <main>
            <section className="container stylization maincont">
                <h1 className="main-ttl">
                <span>Search for Transactions</span>
                </h1>
                <div className="auth-wrap">
                <div className="auth-col">
                    <form onSubmit={this.searchForTransactions} className="login">
                    <p  className="contactform-field contactform-text">
                        <label htmlFor="fromDate"  className="contactform-label">From Date:</label>
                        <span className="contactform-input">
                        <DatePicker
                          selected={this.state.fromDate}
                          onChange={(date) => this.handleFromDateChange(date)}
                          className="form-control date-picker"
                          placeholderText="From Date"
                        />
                        </span>
                    </p>
                    <p  className="contactform-field contactform-text"> 
                        <label htmlFor="toDate"  className="contactform-label">To Date:</label>
                        <span className="contactform-input">
                        <DatePicker
                          selected={this.state.toDate}
                          onChange={(date) => this.handleToDateChange(date)}
                          className="form-control date-picker"
                          placeholderText="To Date"
                        />
                        </span>
                    </p>
                    <p className="contactform-field contactform-text">
                    <label htmlFor="originURL" className="contactform-label">Origin URL:</label>
                    <span className="contactform-input">
                      <Form.Control
                        id="originURL"
                        as="select"
                        value={this.state.originURL}
                        onChange={this.handleOriginURLChange}
                        style={{"width": "80%"}}
                      >
                        <option
                          value=""
                          disabled
                          hidden
                          style={{ color: "#999" }}
                        >
                          &#x2015; Select Origin URL &#x2015;
                        </option>
                        {this.state.originURLs.map((category) => (
                          <option key={category} value={category}>
                            {category}
                          </option>
                        ))}
                      </Form.Control>
                    </span>
                    </p>

                    <p className="auth-submit">
                        <input
                        type="submit"
                        value="Search Transactions"
                        disabled={this.state.isLoading}
                        />
                    </p>

                    <p className="auth-submit">
                        <button
                          onClick={this.downloadTransactions}
                          disabled={this.state.isLoading}
                        >
                          Download Transactions
                        </button>
                      </p>

                    </form>
                    {this.state.error && (
                    <div className="err404">
                        <p className="err404-search">{this.state.error}</p>
                    </div>
                    )}
                </div>
                </div>
            </section>

            <TransactionList title="Search Results" transactionList={this.state.filteredData} />

            <section className="container stylization maincont">
            <h1 className="main-ttl">
              <span>Get Transactions by Payout IDs</span>
            </h1>

            <div className="auth-wrap">
              <div className="auth-col">
                <form className="form-validate">
                  <p className="contactform-field contactform-text">
                    <label className="contactform-label">
                      Payout ID
                    </label>
                    <span className="contactform-input">
                      <Form.Control
                        id="amount"
                        as="select"
                        value={this.state.receiptType}
                        onChange={this.handleReceiptChange}
                      >
                        <option
                          value=""
                          disabled
                          hidden
                          style={{ color: "#999" }}
                        >
                          &#x2015; Choose an option &#x2015;
                        </option>
                        {this.state.payoutIDList.map((category) => (
                          <option key={category} value={category}>
                            {category}
                          </option>
                        ))}
                      </Form.Control>
                    </span>
                  </p>


                  <p>
                    <button
                      onClick={this.downloadPayoutTransactions}
                      disabled={this.state.isLoading}
                    >
                      Download Transactions
                    </button>
                  </p>

                  <p>
                    <button
                      onClick={this.listPayoutTransactions}
                      disabled={this.state.isLoading}
                    >
                      List Transactions
                    </button>
                  </p>
                </form>
                {this.state.error && (
                  <div className="err404">
                    <p className="err404-search">{this.state.error}</p>
                  </div>
                )}
              </div>
            </div>
          </section>
          <TransactionList title="Transaction List" transactionList={this.state.payoutData} />

        </main>
        <ToastContainer />
        {this.state.isLoading && <Spinner />}
      </>
    );
  }
}

export default Transactions;